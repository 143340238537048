export function lonlat(longitude, latitude, height, flg = true) {
  if (flg) {
    let x = Cesium.Math.toDegrees(longitude);
    let y = Cesium.Math.toDegrees(latitude);
    if (height !== false) {
      return [x, y, height];
    } else {
      return [x, y];
    }
  } else {
    //转弧度
    let x = Cesium.Math.toRadians(longitude);
    let y = Cesium.Math.toRadians(latitude);
    if (height !== false) {
      return [x, y, height];
    } else {
      return [x, y];
    }
  }
}

// 创建dom点  showFlag为true时  点是隐藏的  false时点显示的
export function createDomPin(
  earth,
  position,
  css,
  dom,
  px = 0,
  show = false,
  className,
  img = "",
  showFlag = false
) {
  const evalString = `
                if (p._div) {
                    return;
                }

                const earth = p.earth;

                // 创建div
                const div = document.createElement('div');
                div.style.cssText = \`${css}\`;
                div.className = \`${className}\`;
                div.innerHTML = \`${dom}\`;
                div.setAttribute('flag',1);
                div.onclick = () => {
                    const position = [${position[0]}, ${position[1]}, ${position[2]}];
                };
                const container = earth.czm.viewer.container;
                container.appendChild(div);
                p._div = div;

                // 自动删除div
                // disposers数组会在p销毁时执行，从而销毁Pin所附带的资源，避免内存泄露
                p.disposers.push(() => {
                    container.removeChild(div);
                    p._div = undefined;
                });

                // 动态修改div的位置
                p.disposers.push(XE.MVVM.watch(() => [...p.winPos], () => {
                  div.style.left =(p.winPos[0] - div.clientWidth * 0.5) + 'px';
                  div.style.bottom = (p.winPos[3]+${px}) + 'px';
                }));

                // pin距离相机太近则隐藏
                p.disposers.push(XE.MVVM.watch(() => ({
                    enabled: p.enabled,
                    position: [...earth.camera.position],
                }), () => {
                    // xepd: xepositionDistance的缩写
                    // pin1.position.xepd(earth.camera.position): 计算相机和pin之间的距离
                    const d = p.position.xepd(earth.camera.position);
                    if ((d > 10  && d < 1007500 && p.enabled) || div.getAttribute('flag') == 1) {
                        div.style.display = 'block';
                        div.setAttribute('flag',0)
                    } else {
                        div.style.display = 'none';
                    }
                }));
            `;

  // 节点创建方法2：
  {
    const objConfig = {
      name: "Pin",
      xbsjType: "Pin",
      position: position,
      near: 9,
      imageUrl: img,
      far: 99999999,
      show: show,
      showFlag: showFlag,
      showDom: className,
      evalString: evalString

      // customProp :"我是石潭埔我是石潭埔我是石潭埔我是石潭埔"
    };
    const pin = new XE.Obj.Pin(earth);
    pin.xbsjFromJSON(objConfig);
    earth.sceneTree.root.children.push(new XE.SceneTree.Leaf(pin));
    return pin;
  }
}