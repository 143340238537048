import { createStore } from 'vuex'
//创建实例
export default createStore({
        state: {
            test: 1
        },
        getters: {

        },
        mutations: {
            setTest(state, val) {
                state.test = val
            }
        },
        actions: {},
        modules: {}
    })
    //仓库导出为一个模块
    // export default store;