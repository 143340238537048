import { createRouter, createWebHashHistory  } from 'vue-router';

const routerHistory = createWebHashHistory();
const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../pages/Index.vue'),
        meta: {
            title: "主页",
            keepAlive: true
        },
        redirect: "/Router1",
        children: [
            {
              path: "/Router1",
              component: () => import("@/components/Router1"),
              name: "Router1",
            },
            {
                path: "/Router2",
                component: () => import("@/components/Router2"),
                name: "Router2",
              },
              {
                path: "/Router3",
                component: () => import("@/components/Router3"),
                name: "Router3",
              },
              {
                path: "/Router4",
                component: () => import("@/components/Router4"),
                name: "Router4",
              },
          ],
    },

]
const router = createRouter({
    history: routerHistory, // history
    routes
});

export default router;